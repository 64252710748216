import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useDispatch } from "react-redux";
import { validateTimesheet } from "../common/Joi_Validation";
import { addTimesheet, fetchAllTimesheetData } from "../../slices/Timesheet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { socketIO } from "../../App";

const AddTimesheet = ({ setAddTimesheet, currentDate, Project, leads }) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [loadingAddingTimesheet, setLoadingAddingTimesheet] = useState(false);
  const [firstName] = useState(localStorage.getItem("firstName"));
  const [lastName] = useState(localStorage.getItem("lastName"));
  const employeeId = localStorage.getItem("employeeID");
  const name = `${firstName} ${lastName}`;
  const textareaRef = useRef(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateInputRef = useRef(null);
 
 

  const handleFocusDateInput = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
      dateInputRef.current.showPicker();
    }
  };

  // const handleEditTimesheetChange = (e) => {
  //   textareaRef.current.style.height = "auto";
  //   textareaRef.current.style.height = ${textareaRef.current.scrollHeight}px;
  // };


  const handleEditTimesheetChange = (e) => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [formData, setFormData] = useState({
    employee: name,
    employeeId: localStorage.getItem("employeeID"),
    date: formatDate(currentDate),
    project: [],
    details: "",
    projectLead: [],
  });

  const handleSubmit = async (event) => {

    // console.log(formData, " hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
    event.preventDefault();

    const validationErrors = validateTimesheet(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // console.log(errors, " hhhhhhhhhhhhhhhhhh")
    try {
      if (!loadingAddingTimesheet) {
        setLoadingAddingTimesheet(true);
        await axios.post(
          `${process.env.REACT_APP_API_BASE_PATH}/api/timesheets`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                localStorage.getItem("token") || Cookies.get("token")
              }`,
            },
          }
        );
        socketIO.emit("timesheetnotification");
        dispatch(addTimesheet(formData));
        dispatch(fetchAllTimesheetData({ employeeId }));

        toast.info("Add Timesheet Successfully", {
          position: "top-right",
        });
        setFormData({
          employee: name,
          employeeId: localStorage.getItem("employeeID"),
          date: "",
          project: null,
          details: "",
          projectLead: [],
        });
        setErrors({});
        setLoadingAddingTimesheet(false);
      }
      setAddTimesheet(false);
    } catch (error) {
      if (!loadingAddingTimesheet) {
        toast.error("Adding Timesheet Failed", {
          position: "top-right",
        });
        console.error("Error creating timesheet:", error);
        setLoadingAddingTimesheet(false);
        if (error.response.status === 401) {
          localStorage.clear();
          Cookies.remove("token");
          navigate("/login");
        }
      }
    }
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({ ...formData, [name]: selectedOption });
  };

  const handleChange = (event) => {
    var { name, value } = event.target;
    value = value.replace(/'/g, '"');
    setFormData({ ...formData, [event.target.name]: value });
  };

  const projectOptions = Project?.filter(
    (project) => project?.projectStatus !== "Blocked"  && project?.projectStatus !== "Done"
  ).map((project) => ({
    value: project.projectName,
    label: project.projectName,
  }));

  return (
    <Container>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group className="custom-form-input">
              <Form.Label>Project Manager</Form.Label>
              <Select
                options={leads}
                value={formData.projectLead}
                placeholder="@ Select Member..."
                name="projectLead"
                id="projectLead"
                onChange={handleSelectChange}
                isMulti
              />
              {errors.projectLead && (
                <div className="text-danger">{errors.projectLead}</div>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div onClick={handleFocusDateInput} style={{ cursor: "pointer" }}>
            <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.date}
                  name="date"
                  onChange={handleChange}
                  ref={dateInputRef}
                />
                {errors.date && (
                  <div className="text-danger">{errors.date}</div>
                )}
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Form.Group className="mt-3">
          <Form.Label>Project Name</Form.Label>
          <Select
            options={projectOptions}
            value={formData.project}
            placeholder="@ Select project name..."
            name="project"
            id="project"
            onChange={handleSelectChange}
            isMulti
          />
          {errors.project && (
            <div className="text-danger">{errors.project}</div>
          )}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={20} // Initial rows count, will expand automatically
            placeholder="Enter description..."
            onChange={handleChange}
            name="details"
            ref={textareaRef}
            value={formData.details}
          />
          {errors.details && (
            <div className="text-danger">{errors.details}</div>
          )}
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={loadingAddingTimesheet}
          className="mt-3"
        >
          {loadingAddingTimesheet ? "Submitting..." : "Submit"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setAddTimesheet(false)}
          className=" mt-3 ms-3"
        >
          Cancel
        </Button>
      </Form>
    </Container>
  );
};

export default AddTimesheet;
