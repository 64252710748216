import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
  // tableDestroy: false,
  allUsersData: {
    users: [],
    loading: true,
    error: null,
    unreadEmployees: null,
  },
};

const employeeId = localStorage.getItem("employeeID")


export const fetchAllUsersData = createAsyncThunk(
  "users/allUsersData",
  async (args, { rejectWithValue }) => {
    // console.log("to see how many times the api is called /, cccccccccccccccc ");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_PATH}/api/users`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token") || Cookies.get("token")
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status, "error.response.status");
        return "Unauthorized";
      } else {
        throw error;
      }
    }
  }
);

export const realAllEmployees = createAsyncThunk(
  "/realAllEmployees",
  async ({ employeeId, access }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_PATH}/api/users/read/${employeeId}/${access}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token") || Cookies.get("token")
            }`,
          },
        }
      );
      return true;
    } catch (error) {
      console.error("API Update Error:", error);
      if (error.response && error.response.status === 401) {
        return rejectWithValue("Unauthorized");
      } else {
        throw error;
      }
    }
  }
);

const allUsersDataSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    markEmployeesAsViewed: (state) => {
      state.allTimesheetData.unreadEmployees = null;
    },
    addUser: (state, action) => {
      state.allUsersData.users.push(action.payload);
    },
    removeUser: (state, action) => {
      state.allUsersData.users = state.allUsersData.users.filter(
        (user) => user._id !== action.payload
      );
    },
    // tableInitial: (state, { payload }) => {
    //   state.tableDestroy = payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsersData.pending, (state) => {
        state.allUsersData.loading = true;
        state.allUsersData.error = false;
      })
      .addCase(fetchAllUsersData.fulfilled, (state, action) => {
        state.allUsersData.loading = false;
        state.allUsersData.users = action.payload;
        // state.allUsersData.unreadEmployees = action?.payload?.find((x) => x.isRead === false);
      })
      .addCase(fetchAllUsersData.rejected, (state, action) => {
        state.allUsersData.loading = false;
        state.allUsersData.error = true;
        state.allUsersData.users = [];
      })
      .addCase(realAllEmployees.fulfilled, (state) => {
        state.allUsersData.unreadEmployees = null;
        state.allUsersData.users.forEach((user) => {
          user.isRead = true;
        });
      });
  },
});

export default allUsersDataSlice.reducer;
export const { addUser, removeUser, markEmployeesAsViewed, tableInitial } =
  allUsersDataSlice.actions;
