import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Card, Button } from "react-bootstrap";
 


export default function Title({
  setEmployeeId,
  currentDate,
  handleAddTimesheetClick,
  leads,
  employeeId
}) {
 
  const admin = localStorage.getItem("admin");
  const defaultLead = leads?.find(lead => lead.value === employeeId);
  const [selectedOption, setSelectedOption] = useState(defaultLead);

  useEffect(() => {
    if (admin === "true") {
      const id = selectedOption?.value;
      setEmployeeId(id);
    }
  }, [selectedOption]);

  return (
    <div className="d-lg-flex d-md-flex justify-content-between align-items-center mb-3">
      <Card.Title className="mb-lg-0" style={{fontSize:"17px"}}>{`${new Date(
        currentDate
      ).toLocaleDateString("en-GB")} Timesheet`}</Card.Title>
      <div className="d-md-flex">
        {admin === "true" && (
          <div style={{ width: "260px" }}>
            <Select
              options={leads}
              value={selectedOption}
              onChange={setSelectedOption}
              placeholder="Select Employee..."
              className="me-2"
              classNamePrefix="select"
            />
          </div>
        )}

        {admin === "false" && (
          <Button variant="soft-primary" style={{fontSize:"14px",padding:"5px 10px"}}  onClick={handleAddTimesheetClick}>
            <i className="bi bi-plus-circle me-2"></i>Add Timesheet
          </Button>
        )}
      </div>
    </div>
  );
}
