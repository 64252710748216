import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import trash from "../../images/trash.gif";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchAllTimesheetData, removeTimesheet } from "../../slices/Timesheet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function DeleteModal({ show, handleClose, selectedEmployee }) {
  const [deleteTimesheet, setDeleteTimesheet] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeId = localStorage.getItem("employeeID")
 

  const EmployeeDelete = async (employee) => {
    try {
      setDeleteTimesheet(true);
   
      // console.log("deleteTimesheet",employeeId,  deleteTimesheet);
      if (!deleteTimesheet) {
      const response=  await axios.delete(
          `${process.env.REACT_APP_API_BASE_PATH}/api/timesheets/${employee?._id}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                localStorage.getItem("token") || Cookies.get("token")
              }`,
            },
          }
        );
        dispatch(removeTimesheet(employee?._id));
        dispatch(fetchAllTimesheetData({employeeId}));
        toast.info("Deleting Timesheet Successful!", {
          position: "top-right",
        });
        setDeleteTimesheet(false);
      }
      handleClose();
    } catch (error) {
      if (!deleteTimesheet) {
        setDeleteTimesheet(false);
        toast.error("Deleting Timesheet Caused Error!", {
          position: "top-right",
        });
        console.error("Error creating timesheet:", error);
        if (error.response.status === 401) {
          console.log(error.response.status, "error.response.status");
          localStorage.clear();
          Cookies.remove("token");
          navigate("/login");
        }
      }
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose} dialogClassName="modal-md">
      <Modal.Body className="text-center" style={{ paddingTop: "0px" }}>
        <img src={trash} alt="" />
        <p> Are you sure ?</p>
        <p> Are you certain you want to delete this record ?</p>

        <Button
          variant="primary"
          className="me-3"
          onClick={() => {
            EmployeeDelete(selectedEmployee);
          }}
          disabled={deleteTimesheet}
        >
          {deleteTimesheet ? "Deleting..." : "Yes! Delete It"}
        </Button>
        <Button variant="secondary" onClick={handleClose} className="ms-3">
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
}
