import React, { useState } from "react";
import { Badge, Table } from "react-bootstrap";
import AddEditButton from "../../common/AddEditButton";
import LeaveRow from "./LeaveRow";

export default function LeaveTable({
  leaves,
  handleDelete,
  handleStatusChange,
  handleShow,
  isLoading,
  setFromReadMore,
}) {
  const [expandedRowIndex] = useState(null);
  const isAdmin = localStorage.getItem("admin") === "true";

  function truncateContent(content, maxLines = 2, maxLength = 35) {
    const lineLength = 50; 
    const lines = content?.split("\n");

    if (lines?.length === 1 && content?.length <= maxLength) {
      return content;
    } else if (lines?.length === 1 && content?.length > maxLength) {
      return content?.slice(0, maxLength);
    } else if (lines?.length > 1) {
      let truncatedContent = "";
      let currentLineLength = 0;

      for (let i = 0; i < lines?.length; i++) {
        const line = lines[i];
        currentLineLength += line?.length + 1;

        if (currentLineLength <= lineLength) {
          truncatedContent += line + "\n";
        } else {
          truncatedContent +=
            line?.slice(0, lineLength - currentLineLength) + "...\n";
          break;
        }

        if (i + 1 >= maxLines) {
          break;
        }
      }

      return truncatedContent.trim();
    }
  }
  return (
    <div>
      <Table
        responsive
        bordered
        className="mb-0 table-nowrap maindatatable"
        size="sm"
      >
        <thead className="table-light">
          <tr>
            {(isAdmin && localStorage.getItem("hr") === "false") ||
            (!isAdmin && localStorage.getItem("hr") === "true") ||
            (!isAdmin && localStorage.getItem("lead") === "true") ? (
              <td style={{fontSize:"12.8px", fontWeight:"500"
              }}>Name</td>
            ) : null}
            <th>Date</th>
            <th>Leave Type</th>
            <th>Leave From</th>
            <th>Leave To</th>
            <th>No of Days</th>
            <th>Status</th>

            {(isAdmin && localStorage.getItem("hr") === "false") ||
            (!isAdmin && localStorage.getItem("hr") === "true") ||
            (!isAdmin && localStorage.getItem("lead") === "true") ? (
              <th>Admin Status</th>
            ) : null}

            {!isAdmin && localStorage.getItem("hr") === "false" && (
              <th>Reason</th>
            )}

            <th>Leader Name</th>

            {(isAdmin && localStorage.getItem("hr") === "false") ||
            (!isAdmin && localStorage.getItem("hr") === "true") ||
            (!isAdmin && localStorage.getItem("lead") === "true") ? (
              <th>Marked By</th>
            ) : null}

            {isAdmin && localStorage.getItem("hr") === "false" && (
              <th>Applied By</th>
            )}

            {(!isAdmin && localStorage.getItem("lead") === "false") ||
              (!isAdmin && localStorage.getItem("lead") === "true") ||

            (!isAdmin && localStorage.getItem("hr") === "true") ? (
              <th>Action</th>
            ) : null}
          </tr>
        </thead>

        <tbody>
          {leaves?.map((data, i) => {
            // {console.log("hhhhhhhhhhh")}
            return (
              <LeaveRow
                key={data._id}
                data={data}
                handleDelete={handleDelete}
                handleStatusChange={handleStatusChange}
                handleShow={handleShow}
                isAdmin={isAdmin}
                isLoading={isLoading}
                truncateContent={truncateContent}
                setFromReadMore={setFromReadMore}
                expandedRowIndex={expandedRowIndex}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
