import React, { useEffect } from "react";
import { Container, Form, Dropdown } from "react-bootstrap/";
import Cookies from "js-cookie";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loggedUserDetails } from "../../slices/userSlice";
import { tableInitial } from "../../slices/Users";
export default function TopBar() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user.userDetails);
  const tableDestroy = useSelector((state) => state.users.tableDestroy);

  useEffect(() => {
    dispatch(loggedUserDetails(localStorage.getItem("employeeID")));
  }, [dispatch]);

//   const initial = userInfo?.firstName?.charAt(0).toUpperCase();
// const tab = localStorage.getItem("table");
  
  const handleLogout = () => {
    navigate("/login");
    localStorage.clear();
    Cookies.remove("token")
    // dispatch(tableInitial(true))
    // console.log(tableDestroy, " tablednsdihxhksdv ")
  };
  return (
    <>
      <div className="topbar">
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center"></div>
            <div className="topbar-right-content">
              <ul className="list-unstyled d-flex align-items-center mb-0">
                <li className="topbar-icon d-block d-md-none me-3">
                  <Dropdown>
                    {/* <Dropdown.Toggle
                      variant=" p-0 top-drop-btn"
                      id="dropdown-basic"
                    >
                      <div className="topbar-avatar text-secondary avatar avatar-md rounded">
                        <i className="bi bi-search avatar-icon"></i>
                      </div>
                    </Dropdown.Toggle> */}
                    <Dropdown.Menu
                      className="dropdown-xl"
                      align="end"
                      title="Dropdown end"
                    >
                      <div className="top-search-sm">
                        <Form className="top-search-input-content">
                          <Form.Group className="top-search-input">
                            <i className="bi bi-search fs-20 top-search-icon"></i>
                            <Form.Control
                              type="email"
                              placeholder="Search..."
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="topbar-user">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=" p-0 top-drop-btn top-user-drop-btn"
                      id="dropdown-basic"
                    >
                      <span className="top-user-img rounded-circle">
                        <img
                         src={userInfo.profilephoto}
                         alt=""
                          className="rounded-circle "
                          style={{
                            width: "45px",
                            height: "45px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          
                        </img>
                      </span>
                      <span className="top-user-name d-lg-block d-none c-ms-10">
                        <h6 className="c-mb-2 fs-15">{userInfo?.firstName}</h6>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" title="Dropdown end">
                      <Dropdown.Item href="/employee_profile">
                        <i className="bi bi-person fs-18 me-2"></i> Profile
                      </Dropdown.Item>

                      <Dropdown.Item href="#/action-4">
                        <i className="bi bi-file-text fs-18 me-2"></i> Guide
                      </Dropdown.Item>

                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-6" onClick={handleLogout}>
                        <i className="bi bi-box-arrow-right fs-18 me-2"></i>{" "}
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
