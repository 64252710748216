import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socketIO } from "../App";
import { newTimesheetRequestt, seenTimesheetByAdmin } from "../slices/Timesheet";


export const TimesheetSocketClient = () => {
  const isAdminOrLead =
    localStorage.getItem("admin") === "true" ||
    localStorage.getItem("hr") === "true";
  const dispatch = useDispatch();

// console.log('isAdminOrLead')
useEffect(() => {
    // console.log('isAdminOrLead2d',isAdminOrLead)

    socketIO.on("pendingtimesheetRequestt",  (data) => {
        // console.log('isAdminOrLeadggggggggggg', isAdminOrLead)
        if(isAdminOrLead){
          dispatch(seenTimesheetByAdmin());
      }  
    });
    return () => {
        socketIO.off("pendingtimesheetRequestt");
    };

  }, [dispatch]);

  return null;
};
