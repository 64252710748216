import React from 'react';
import { Col, Form } from 'react-bootstrap';

const ModalFormMobileNoField = ({ controlId, label, placeholder, name, value, onChange, errors }) => {
  return (
    <Col lg={6} xl={4}>
      <Form.Group controlId={controlId} className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        />
        {errors && (
          <p className="text-danger">{errors}</p>
        )}
      </Form.Group>
    </Col>
  );
};

export default ModalFormMobileNoField;
