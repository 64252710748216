import React from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';


export default function PageTitle(props) {
    // console.log(props , " sssssssssss ")
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className='d-flex justify-content-between align-items-center page-title-content'> 
                            <div className='d-flex align-items-center text-primary'>
                            {props?.editForm && <i className='bi bi-arrow-left open me-3 fs-2' onClick={() => props.setEditForm(false)} ></i> }
                            <h3 className='page-title' style={{fontSize:"17px"}} >{props.breadcrumbLast}</h3>
                            </div>
                                
                            <Breadcrumb className='d-none d-md-block'>
                                <Breadcrumb.Item href="/" style={{fontSize:"15px"}}>HRM</Breadcrumb.Item>
                                {props.breadcrumb1 ? <Breadcrumb.Item style={{fontSize:"15px"}}>{props.breadcrumb1}</Breadcrumb.Item> : ""}
                                {props.breadcrumb2 ? <Breadcrumb.Item style={{fontSize:"15px"}}>{props.breadcrumb2}</Breadcrumb.Item> : ""}
                                {props.breadcrumb3 ? <Breadcrumb.Item style={{fontSize:"15px"}}>{props.breadcrumb3}</Breadcrumb.Item> : ""}
                                <Breadcrumb.Item active>{props.breadcrumbLast}</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
