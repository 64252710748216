import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../components/common/Service';

const initialState = {
  data: [],
  unreadLeavess: null,
  isLoading: false,
  error: null,
  // isNewRemoteRequest: false
  isNewRemoteRequest:null

};
// Define thunks for async actions
export const fetchWorkFromHome = createAsyncThunk(
  'workFromHome/fetchWorkFromHome',
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/api/leave/remoteusers?employee=${employeeId}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const seenRequestidss=createAsyncThunk(
  "/workFromHome/seenRequestids",
  async()=>{
  const response=await axiosInstance.get(
    "/api/leave/remoteusers/seenrequestids"
  );
  // console.log("filter data2222",response.data.data)
  return response.data.data;
  }
)

export const addWorkFromHome = createAsyncThunk(
  'workFromHome/addWorkFromHome',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/leave/remoteusers?employee=${requestData.employeeId}`, requestData);
      return response.data.WorkHomeRequest;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
export const updateWorkFromHome = createAsyncThunk(
  'workFromHome/updateWorkFromHome',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/api/leave/remoteusers/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
export const deleteWorkFromHome = createAsyncThunk(
  'workFromHome/deleteWorkFromHome',
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/api/leave/remoteusers/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const realAllLeavess = createAsyncThunk('/realAllLeavess', async (employeeId,access) => {
    // console.log('access', access)
   const response= await axiosInstance.put(`/api/leave/remoteusers/read/${employeeId.employeeId}/${employeeId.access}`);
  //  console.log("rrrr",response)
    return true;
  });
// Create a slice with reducers and actions
const workFromHomeSlice = createSlice({
  name: 'workFromHome',
  initialState,
  reducers: {
  
    // markLeavesAsViewedd: (state, action) => {
    //   state.unreadLeavess= null;
    // },
    newRemoteRequest:(state,action)=>{
      // console.log("oooooooooooooooooo",action.payload)
      state.isNewRemoteRequest=action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkFromHome.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWorkFromHome.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        // state.unreadLeavess = action?.payload?.find((x)=>x.isRead==false);
        state.error = null;
      })
      .addCase(fetchWorkFromHome.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(seenRequestidss.pending, (state) => {
        state.status = "loading";
      })
      .addCase(seenRequestidss.fulfilled, (state, action) => {
        state.status = "succeeded";      
        state.unreadLeavess = action?.payload;
        // state.isNewRequest = action?.payload?.find((x) => x.isRead == false);
      })
      .addCase(seenRequestidss.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addWorkFromHome.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addWorkFromHome.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addWorkFromHome.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateWorkFromHome.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWorkFromHome.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.map((item) =>
          item._id === action.payload._id ? action.payload : item
        );
        state.error = null;
      })
      .addCase(updateWorkFromHome.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteWorkFromHome.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteWorkFromHome.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.filter((item) => item._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteWorkFromHome.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(realAllLeavess.fulfilled, (state, action) => {
        // state.unreadLeavess = 0;
        state.isNewRemoteRequest = null;
        state.data.forEach(leave => {
          leave.isRead = true;
        });
      })
  },
});
// Export actions and reducer
export const { clearError ,markLeavesAsViewedd,newRemoteRequest} = workFromHomeSlice.actions; 
export default workFromHomeSlice.reducer;