import React from "react";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <h1>Page Not Found</h1>
          <Link to="/" className="home-link">Go to Homepage</Link>
        </div>
      </div>
      {/* <p>hh</p> */}
    </div>
  );
}
