import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socketIO } from "../App";
import { newRequestt, seenProjectByAdmin } from "../slices/Project";


export const ProjectSocketClient = () => {
  const isAdminOrLead =
    localStorage.getItem("admin") === "true" ||
    localStorage.getItem("hr") === "true";
  const dispatch = useDispatch();

useEffect(() => {
    socketIO.on("pendingLeaveRequestt",  (data) => {
        if(isAdminOrLead){
          dispatch(seenProjectByAdmin());
      }  
    });
    return () => {
        socketIO.off("pendingLeaveRequestt");
    };

  }, [dispatch]);

  return null;
};
