import React from 'react';
import { Col, Form } from 'react-bootstrap';

export default function ModalFormBasicTextField({
  value,
  controlIdAndName,
  formLabelAndPlaceholder,
  changeFunction,
  errors
}) {
    // console.log(errors, controlIdAndName,value , "ghhhhhhhhhhhhhhhhhh")
  return (
    <Col lg= {controlIdAndName === "address" ? 12 :  4 } >
      <Form.Group controlId={controlIdAndName} className="mb-3">
        <Form.Label>{formLabelAndPlaceholder}</Form.Label>
        <Form.Control
          type="text"
          placeholder={`Enter ${formLabelAndPlaceholder} ... `}
          name={controlIdAndName} // Ensure dynamic naming for the field
          value={value}
          onChange={changeFunction}
        />
        {errors && (
          
            controlIdAndName === "panCardNo" || "aadharCardNo" ?
        (

            <div className="text-danger">
            {errors}
          </div>
        )
           : 
           (<div className="text-danger">
           {`${formLabelAndPlaceholder} is not allowed to be empty`}
         </div>)
          
       
        )}
      </Form.Group>
    </Col>
  );
}
