import React, { memo } from "react";
import { Badge, Button } from "react-bootstrap";
import AddEditButton from "../../common/AddEditButton";

const LeaveRow =({ data, handleDelete, handleStatusChange,setFromReadMore,expandedRowIndex,truncateContent, handleShow, isAdmin, isLoading,employeeID }) => {
  const isLead = localStorage.getItem("lead") === "true";
  const leadId = localStorage.getItem("employeeID");
  const isLeadMatch = isLead && leadId === data.employeeId; 
  return (
            <tr key={data._id}>
              {(isAdmin && localStorage.getItem("hr") === "false") ||
              (!isAdmin && localStorage.getItem("hr") === "true") ||
              (!isAdmin && localStorage.getItem("lead") === "true") ? (
                <td>
                  {data.fromAdmin ? data?.employeename?.label : data?.name}
                </td>
              ) : null}

              <td>{new Date(data?.requestDate).toLocaleDateString()}</td>
              <td>{data?.leavetype}</td>
              <td>{new Date(data?.leavefrom).toLocaleDateString()}</td>
              <td>
                {data?.leavetype === "First Half" ||
                data?.leavetype === "Second Half"
                  ? "-"
                  : new Date(data?.leaveto).toLocaleDateString()}
              </td>
              <td>
                {data?.leavetype === "First Half" ||
                data?.leavetype === "Second Half"
                  ? "0.5"
                  : data?.noofdays}
              </td>

              <td>
                <span
                  className={`badge ${
                    data.status === "Pending"
                      ? "bg-soft-warning"
                      : data.status === "Approved"
                      ? "bg-soft-success"
                      : "bg-soft-danger"
                  } text-dark`}
                >
                  {data?.status}
                </span>
              </td>
              {(isAdmin && localStorage.getItem("hr") === "false") ||
              (!isAdmin && localStorage.getItem("hr") === "true") ||
              (!isAdmin && localStorage.getItem("lead") === "true") ? (
                <td>
                  <select
                    className="form-select small-select"
                    value={data.statusText}
                    disabled={isLeadMatch}
                    onChange={(e) => handleStatusChange(e, data._id)}
                  >
                    <option value="Requested">Select</option>
                    <option value="Approved">✔️</option>
                    <option value="Rejected">❌</option>
                  </select>
                </td>
              ) : null}

              {!isAdmin && localStorage.getItem("hr") === "false" && (
                <td
                  style={{
                    whiteSpace: "pre-line",
                    wordWrap: "break-word",
                    wordBreak: "break-word",
                  }}
                >
                  {expandedRowIndex === data._id
                  ? data?.reason
                  : truncateContent(data.reason)}
                {data?.reason?.length > 50 && (
                  <span
                    style={{ cursor: "pointer", color: "#1E90FF" }}
                    onClick={() => {
                      handleShow(data);
                      setFromReadMore(true);
                    }}
                  >
                    {expandedRowIndex === data._id ? " Read less" : " ....Read more"}
                  </span>
                )}
                 
                </td>
              )}
              <td>
                {data?.lead?.map((lead, index) => (
                  <Badge key={index} pill bg="primary" className="me-1">
                    {lead?.label}
                  </Badge>
                ))}
              </td>

              {(isAdmin && localStorage.getItem("hr") === "false") ||
              (!isAdmin && localStorage.getItem("hr") === "true") ||
              (!isAdmin && localStorage.getItem("lead") === "true") ? (
                <td>{data?.approvedBy}</td>
              ) : null}

              {isAdmin && localStorage.getItem("hr") === "false" && (
                <td>{data.fromAdmin ? data.name : data.name}</td>
              )}

              {(!isAdmin && localStorage.getItem("lead") === "false") ||
            (!isAdmin && localStorage.getItem("lead") === "true") ||

              (!isAdmin && localStorage.getItem("hr") === "true") ? (
                <td>
                  <AddEditButton
                    isLoading={isLoading}
                    handleShow={handleShow}
                    data={data}
                    handleDelete={handleDelete}
                    setFromReadMore={setFromReadMore}
                  />
                </td>
              ) : null}
            </tr>
  );
};

export default React.memo(LeaveRow);
