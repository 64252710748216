import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socketIO } from "../App";
import { newRemoteRequest, seenRequestidss } from "../slices/RemoteWorkSlice";


export const RemoteSocketClient = () => {
  const isAdminOrLead =
    localStorage.getItem("admin") === "true" ||
    localStorage.getItem("hr") === "true";
  const dispatch = useDispatch();

// console.log('isAdminOrLead')
useEffect(() => {
    // console.log('isAdminOrLead2d',isAdminOrLead)

    socketIO.on("pendingremoteRequestt",  (data) => {
        // console.log('isAdminOrLeadggggggggggg', isAdminOrLead)
        if(isAdminOrLead){
          // dispatch(newRemoteRequest(true));
          dispatch(seenRequestidss())
      }  
    });
    return () => {
        socketIO.off("pendingremoteRequestt");
    };

  }, [dispatch]);

  return null;
};
