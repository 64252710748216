import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { fetchAllProjectData, updateProject } from "../../slices/Project";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { validateProjectEdit } from "../common/Joi_Validation";
import Cookies from "js-cookie";
import { socketIO } from "../../App";

export default function EditProjectModal({
  show,
  handleClose,
  project,
  options,
  leadOptions,
}) {
  

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [initialData, setInitialData] = useState(null);

  const navigate = useNavigate();
  const [loadingEditProjectModal, setLoadingEditProjectModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedLead, setSelectedLead] = useState([]);

  useEffect(() => {
    if (project) {
    
      setName(project.projectName);
      setSelectedStatus(
        options.find((option) => option.value === project.projectStatus)
      );
      const initialProjectData = {
        projectName: project.projectName,
        projectStatus: project.projectStatus,
        projectLead: project.projectLead,
      };
      // console.log(initialProjectData, " project.projectLead  to seee in the jjj ")
      setSelectedLead(project.projectLead);
      setInitialData(initialProjectData);
      // console.log(selectedLead, "yyyyyyyyyyy ")
    }
  }, [project, options, leadOptions]);

  const handleEdit = async (event) => {
    const formData = {
      projectName: name,
      projectStatus: selectedStatus ? selectedStatus.value : null,
      projectLead: selectedLead,
      edittedBy: localStorage.getItem("firstName"),
    };

    // console.log(formData, " ddddddddddddd ")
    const validateErrors = validateProjectEdit(formData);

    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      return;
    }
    try {
      if (!loadingEditProjectModal) {
        setLoadingEditProjectModal(true);
        await axios.patch(
          `${process.env.REACT_APP_API_BASE_PATH}/api/projects/${project._id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                localStorage.getItem("token") || Cookies.get("token")
              }`,
            },
          }
        );

        dispatch(updateProject({ ...project, ...formData }));
        dispatch(fetchAllProjectData());
        socketIO.emit("notificationn")
        toast.info("Project updated successfully.");
        setErrors({});
        setLoadingEditProjectModal(false);
      }
      handleClose();
    } catch (error) {
      if (!loadingEditProjectModal) {
        console.error("Error updating project:", error);
        toast.error("Project update failed.");
        setLoadingEditProjectModal(false);
        if (error.response.status === 401) {
          console.log(error.response.status, "error.response.status");
          localStorage.clear();
          Cookies.remove("token");

          navigate("/login");
        }
      }
    }
  };

  const isDataChanged = () => {
    if (!initialData) return false;

    const currentData = {
      projectName: name,
      projectStatus: selectedStatus ? selectedStatus.value : null,
      projectLead: selectedLead,
    };

    return (
      initialData.projectName !== currentData.projectName ||
      initialData.projectStatus !== currentData.projectStatus ||
      JSON.stringify(initialData.projectLead) !== JSON.stringify(currentData.projectLead)
    );
  };

  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        handleClose();
        setErrors({});
      }}
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter project name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {errors.projectName && (
              <Form.Text className="text-danger">
                Project Name is not allowed to be empty
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Select Status</Form.Label>
            <Select
              options={options}
              placeholder="Select Status..."
              value={selectedStatus}
              onChange={setSelectedStatus}
            />
            {errors.projectStatus && (
              <Form.Text className="text-danger">
                Project Status must be of type object
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Select Project Lead</Form.Label>
            <Select
              isMulti
              options={leadOptions}
              placeholder="Select Project Lead..."
              value={selectedLead}
              onChange={setSelectedLead}
            />
            {errors.projectLead && (
              <Form.Text className="text-danger">
                Project Lead must be of type object
              </Form.Text>
            )}
          </Form.Group>
          <Button
            variant="primary"
            // disabled={loadingEditProjectModal}
            disabled={loadingEditProjectModal || !isDataChanged()}
            onClick={(e) => {
              e.preventDefault();
              handleEdit();
            }}
          >
            {loadingEditProjectModal ? "Editing..." : " Save Changes"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setErrors({});
            }}
            className="ms-2"
          >
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
