import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import MenuContext from "../../context/MenuContext";
import Layout from "../layout/Layout";
import Login from "../Login";
import Cookies from "js-cookie";

import Calender from "../calender/MainCalender";
import AddTimesheet from "../calender/AddTimesheet";
import AllLeaveRequest from "../leave/AllLeaveRequest";
import Project from "../project/project";
import WorkFromHome from "../leave/WorkFromHome";
import EmployeeList from "../Employee/allEmployee/EmployeeList";
import EmployeeProfile from "../Employee/individualEmployee/EmployeeProfile";
import PageNotFound from "../pageNotFound/PageNotFound";

 

const PrivateRoute = ({ children }) => {

  const token = localStorage.getItem("token") ||  Cookies.get("token");
  return token ? children : <Navigate to="/login" />;
};

const ProtectedLoginRoute = ({ children }) => {
  const token = localStorage.getItem("token") ||  Cookies.get("token");
  return token ? <Navigate to="/" /> : children;
};

export default function Index() {
  const context = useContext(MenuContext);
  const { iconMenu } = context;

  return (
    <div data-sidebar-size={iconMenu ? "lg" : "sm"}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <ProtectedLoginRoute>
                <Login />
              </ProtectedLoginRoute>
            }
          />

          <Route
            path="/"
            element={
              <PrivateRoute>
                <div>
                  <Layout />
                  <Calender />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/employee"
            element={
              <PrivateRoute>
                <div>
                  <Layout />
                  <EmployeeList />
                </div>
              </PrivateRoute>
            }
          />

          <Route
            path="/workhome"
            element={
              <div>
                <Layout />
                <WorkFromHome />
              </div>
            }
          />

          <Route
            path="/employee_profile"
            element={
              <PrivateRoute>
                <div>
                  <Layout />
                  <EmployeeProfile />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/add-timesheet"
            element={
              <PrivateRoute>
                <AddTimesheet />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/timesheet"
            element={
              <PrivateRoute>
                <div>
                  <Layout />
                  <Calender />
                </div>
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/leave"
            element={
              <PrivateRoute>
                <div>
                  <Layout />
                  <AllLeaveRequest />
                </div>
              </PrivateRoute>
            }
          />
    
          <Route
            path="/project"
            element={
              <PrivateRoute>
                <div>
                  <Layout />
                  <Project />
                </div>
              </PrivateRoute>
            }
          />
           <Route
            path="*"
            element={
              <PrivateRoute>
                <div>
                  <Layout />
                  <PageNotFound/>
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
