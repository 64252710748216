import React from 'react'
import { Col, Form } from 'react-bootstrap';

export default function ModalFormDateField({
    controlId,
    label,
    value,
    onChange,
    errors,
    formatDate,
    handleFocusDateInput,
    dateInputRef,
  }) {
  return (
    <Col lg={6}>
    <div onClick={handleFocusDateInput} style={{ cursor: 'pointer' }}>
      <Form.Group controlId={controlId} className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="date"
          placeholder={`Enter ${label.toLowerCase()} ...`}
          name={controlId}
          value={formatDate(value)}
          onChange={onChange}
          ref={dateInputRef}
        />
        {errors && (
          <p className="text-danger">
            {label} is not allowed to be empty
          </p>
        )}
      </Form.Group>
    </div>
  </Col>
  )
}
