import React from 'react'
import {  Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function PersonalInfoComponent({userInfo}) {
    
    return (
    <Card>
    <Card.Body >
      <Card.Title>Personal Info</Card.Title>
      <Row>
        <Col lg={6}>
          <div className="mb-2">
            <p className="text-muted fs-13 mb-1">Address</p>
            <h5 className="fs-15 text-dark mb-0">{userInfo?.address}</h5>
          </div>
        </Col>
        <Col lg={6} xl={3}>
          <div className="mb-2">
            <p className="text-muted fs-13 mb-1">Date of Birth</p>
            <h5 className="fs-15 text-dark mb-0">
              {userInfo?.dateOfBirth ? new Date(userInfo?.dateOfBirth)?.toLocaleDateString(
                "en-GB"
              ) : null}
            </h5>
          </div>
        </Col>

        <Col md={6} xl={3}>
          <div className="mb-2">
            <p className="text-muted fs-13 mb-1">Gender</p>
            <h5 className="fs-15 text-dark mb-0">{userInfo?.gender}</h5>
          </div>
        </Col>
      </Row>
    </Card.Body>
  </Card>

  )
}
