import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
  leadsData: { leads: [], loading: true, error: null },
};

const employeeId = localStorage.getItem("employeeID")

export const fetchLeadsData = createAsyncThunk(
  "leads/fetchLeadsData",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_PATH}/api/users/leads/${localStorage.getItem("employeeID")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token") || Cookies.get("token")}`,
          },
        }
      );
      // console.log(response , " from theb lead tyo see ")
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return rejectWithValue("Unauthorized");
      } else {
        throw error;
      }
    }
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    // Define any synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadsData.pending, (state) => {
        state.leadsData.loading = true;
        state.leadsData.error = false;
      })
      .addCase(fetchLeadsData.fulfilled, (state, action) => {
        state.leadsData.loading = false;
        state.leadsData.leads = action.payload;
      })
      .addCase(fetchLeadsData.rejected, (state, action) => {
        state.leadsData.loading = false;
        state.leadsData.error = true;
        state.leadsData.leads = [];
       
      });
  },
});

export default leadsSlice.reducer;
// Export any actions if needed
