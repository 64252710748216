import React, { useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";

export default function WorkHomeModal({
  showModal,
  handleClose,
  handleChange,
  handleSubmit,
  formData,
  handleDateChange,
  selectedLeaveId,
  isLoading,
  errors,
  selectedEmployee,
  handleEmployeeChange,
  handleLeadChange,
  selectedLeads,
  options2,
  initialFormData,
  fromReadMore
}) {
  const isAdmin = localStorage.getItem("admin") === "true";
 
  const hasFormDataChanged = () => {
    return JSON.stringify(formData) !== JSON.stringify(initialFormData);
  };

  const  users = useSelector((state) => state.users.allUsersData.users);


  // const employeeOptions=users?.map((employee)=>{
  //   return {value:employee._id,label:employee.firstName+" "+employee.lastName}
  // })
  // Constructing employee options

  const hrUser = localStorage.getItem("hr") === "true";
  console.log("hrUser", hrUser);
  
  let employeeOptions = users.map((employee) => {
    return { value: employee._id, label: `${employee.firstName} ${employee.lastName}` };
  });
  
  // Append HR name if logged-in user is HR
  if (hrUser) {
    const hrName = `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`;
    employeeOptions.push({ value: 'hr', label: `HR: ${hrName}` });
  }

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };
  const dateFromInputRef = useRef(null);
  const dateToInputRef = useRef(null);

  const handleFocusDateFromInput = () => {
    if (dateFromInputRef.current) {
      dateFromInputRef.current.focus();
      dateFromInputRef.current.showPicker();
    }
  };

  const handleFocusDateToInput = () => {
    if (dateToInputRef.current) {
      dateToInputRef.current.focus();
      dateToInputRef.current.showPicker();
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedLeaveId ? "Update Data" : "Apply For Work From Home"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>

             {(isAdmin &&
                localStorage.getItem("hr") === "false") ||
                 (!isAdmin &&
                   localStorage.getItem("hr") === "true") ? (
             <Form.Group controlId="employeeName" className="floating-label">
              <Select
                name="employeename"
                id="employeename"
                options={employeeOptions}
                value={selectedEmployee}
                onChange={handleEmployeeChange}
                isDisabled={fromReadMore}
              />
            </Form.Group>
          ):null}

         <div onClick={handleFocusDateFromInput} style={{ cursor: "pointer" }}>
            <Form.Group controlId="remoteStartDate" className="floating-label">
              <Form.Control
                type="date"
                placeholder=" "
                name="remoteStartDate"
                value={
                  formData?.remoteStartDate
                    ? formatDate(formData.remoteStartDate)
                    : ""
                }
                ref={dateFromInputRef}
                onChange={(e) =>
                  handleDateChange(e.target.value, "remoteStartDate")
                }
                disabled={fromReadMore}
                isInvalid={!!errors.remoteStartDate}
              />
              <Form.Label>Remote Start Date</Form.Label>
              <Form.Control.Feedback type="invalid">
              {errors.remoteStartDate && (
                <div className="text-danger">{errors.remoteStartDate === "Conflict" ? "Cannot select the same remote work on the same date." : "Remote Start Date is not allowed to be empty"}</div>
              )}
              </Form.Control.Feedback>
            </Form.Group>
          </div>


          <div onClick={handleFocusDateToInput} style={{ cursor: "pointer" }}>
            <Form.Group controlId="remoteEndDate" className="floating-label">
              <Form.Control
                type="date"
                placeholder=" "
                name="remoteEndDate"
                value={
                  formData?.remoteEndDate
                    ? formatDate(formData.remoteEndDate)
                    : ""
                }
                min={
                  formData?.remoteStartDate
                    ? formatDate(formData.remoteStartDate)
                    : ""
                }
                ref={dateToInputRef}
                onChange={(e) =>
                  handleDateChange(e.target.value, "remoteEndDate")
                }
                disabled={fromReadMore}
                isInvalid={!!errors.remoteEndDate}
              />
              <Form.Label>Remote End Date</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.remoteEndDate}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <Form.Group controlId="formNoOfDays" className="floating-label">
            <Select
              name="lead"
              id="lead"
              options={options2}
              value={selectedLeads}
              onChange={handleLeadChange}
              isDisabled={fromReadMore}
              isMulti
            />
             {errors.lead && <div className="invalid-feedback d-block">{errors.lead}</div>}
          </Form.Group>

          <Form.Group controlId="reason" className="floating-label">
            <Form.Control
              as="textarea"
              name="reason"
              rows={20}
              value={formData.reason} 
              onChange={handleChange}
              isInvalid={!!errors.reason}
              disabled={fromReadMore}
            />
            <Form.Label>Reason</Form.Label>
            <Form.Control.Feedback type="invalid">
                {errors.reason}
              </Form.Control.Feedback>
          </Form.Group>

          {!fromReadMore&&(
          <Button variant="primary" type="submit" disabled={isLoading||!hasFormDataChanged()}>
            {isLoading?"Submitting":"Submit"}       
          </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
