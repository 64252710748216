import React, { useEffect, useState } from "react";
import PageTitle from "../../layout/PageTitle";
import Footer from "../../layout/Footer";
import { Container, Card, Button, Table} from "react-bootstrap";
import Cookies from "js-cookie";

import DeleteModel from "./DeleteModel";
import user from "../../../images/user1.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsersData, removeUser } from "../../../slices/Users";

// Datatable
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import AddEmployeeModal from "./AddEmployeeModal";
import axios from "axios";
 
import AddEmployeeButton from "./AddEmployeeButton";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";

export default function EmployeeList() {

  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [add, setAdd] = useState(false);
  const [loadingEmployeeDelete, setLoadingEmployeeDelete] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const [inputData, setInputData] = useState(null);

  const { users, loading } = useSelector((state) => state.users.allUsersData);

  const handleShow = (data) => {
    setInputData(data);
    setId(data?._id);
    setShow(true);
  };

  
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const resultAction = await dispatch(fetchAllUsersData());
        console.log(resultAction, " ssssssssssssssss freom gtj 3 ");
        const user = resultAction.payload;
        if (user === "Unauthorized" || !localStorage.getItem("employeeID")) {
          localStorage.clear();
          Cookies.remove("token")

          navigate("/login");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmployee();
     
  }, [dispatch]);


  DataTable.Buttons.jszip(JSZip);
  useEffect(() => {
    if (!$.fn.dataTable.isDataTable(".maindatatable")) {
      $(".maindatatable").DataTable({
        dom: "flrtip",
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            previous: "«",
            next: "»",
          },
        },
      });
    }
  }, [users]);

  const handleDeleteUser = async (id) => {
    try {
      if (!loadingEmployeeDelete) {
        setLoadingEmployeeDelete(false);
        await axios.delete(
          `${process.env.REACT_APP_API_BASE_PATH}/api/users/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                localStorage.getItem("token") ||  Cookies.get("token")
              }`,
            },
          }
        );

        dispatch(removeUser(id));
      }

      handleCloseDelete();
    } catch (error) {
      if (!loadingEmployeeDelete) {
        console.error("Error deleting user:", error);
        setLoadingEmployeeDelete(false);

        if (error.response.status === 401) {
          console.log(error.response.status, "error.response.status");
          localStorage.clear();
          Cookies.remove("token")

          navigate("/login");
        }
      }
    }
  };

  // const options = [
  //   { value: "Admin", label: "Admin" },
  //   { value: "Employee", label: "Employee" },
  // ];

  const hr = localStorage.getItem("hr") === "true" ? true : false; 



  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Employee List" />
          <Container>
            {loading ? (
              <Loader />
            ) : (
              <Card>
                <Card.Body>
                  <AddEmployeeButton handleShow={handleShow} setAdd={setAdd} />

                  {users.length > 0 && (
                     <Table responsive bordered className="mb-0 table-nowrap maindatatable">
                     <thead className="table-light">
                       <tr>
                         <th>Employee Id</th>
                         <th>Employee Name</th>
                         <th>Email</th>
               
                         <th>Contact No</th>
                         <th>Alternate Phone</th>
                         <th>Designation</th>
                         {hr ? <th>Action</th> :  <th>Date Of Birth</th> }
                       
                       </tr>
                     </thead>
                     <tbody>
                       {users?.map((data, i) => (
                         <tr key={i}>
                           <td>{data.employeeNo}</td>
                           <td>
                             <span>
                               {data.firstName} {data.lastName}
                             </span>
                           </td>
               
                           <td>{data.email}</td>
                           <td>{data.contactNo}</td>
                           <td>{data.altContactNo}</td>
                           <td>{data.designation}</td>
               
                           {hr ? (
                             <td>
                               <div className="d-flex">
                                 <Button
                                   variant="soft-primary table-btn me-2"
                                   onClick={() => {
                                     handleShow(data);
                                     setAdd(false);
                                   }}
                                 >
                                   <i className="bi bi-pencil-square"></i>
                                 </Button>
                                 {hr ? (
                                   <Button

                                     variant="soft-danger table-btn"
                                     onClick={() => {
                                       handleShowDelete();
                                       setId(data._id);
                                     }}
                                   >
                                     <i className="bi bi-trash3"></i>
                                   </Button>
                                 ) : null}
                               </div>
                             </td>
                           ) : <td>{new Date(data.dateOfBirth).toLocaleDateString("en-GB")}</td>}
                          
                         </tr>
                       ))}
                     </tbody>
                   </Table>
                    // <EmployeeListTable
                    //   users={users}
                    //   handleShow={handleShow}
                    //   handleShowDelete={handleShowDelete}
                    //   setId={setId}
                    //   lead={lead}
                    //   setAdd={setAdd}
                    // />
                  )}
                </Card.Body>
              </Card>
            )}
          </Container>
        </div>
        <Footer />
      </div>
      <AddEmployeeModal
        show={show}
        handleClose={handleClose}
        employeeEditData={add ? null : inputData}
        setemployeeEditData={setInputData}
        user={user}
        add = {add}
        // options={options}
        id={id}
      />

      <DeleteModel
        show={showDelete}
        loadingEmployeeDelete={loadingEmployeeDelete}
        handleClose={handleCloseDelete}
        id={id}
        handleDeleteUser={handleDeleteUser}
      />
    </div>
  );
}
