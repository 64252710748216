import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";


export default function BasicInfoComponent({ setEditForm , userInfo}) {

 

  return (
    <Card>
      <Card.Body >
        <div className="d-flex align-items-center justify-content-center mb-3">
          <img 
           alt=""
           src={userInfo?.profilephoto}
            className="rounded-circle "
            style={{
              height: "80px",
              width: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          </img>
          <div className="ms-4">
            <h3>{userInfo?.firstName}{" "}{userInfo?.lastName}</h3>
          </div>
          <div className="d-md-flex ms-auto">
            <Button
              variant="soft-primary mb-1 mb-md-0"
              className="me-2"
              style={{fontSize:"14px",padding:"5px 10px"}}
              onClick={() => setEditForm(true)}
            >
              <i className="bi bi-plus-circle me-2"></i>Edit Profile
            </Button>
          </div>
        </div>
        <Card.Title>Basic Info</Card.Title>
        <Row>
          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">First Name</p>
              <h5 className=" fs-15 text-dark mb-0">{userInfo?.firstName}</h5>
            </div>
          </Col>

          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">Last Name</p>
              <h5 className=" fs-15 text-dark mb-0">{userInfo?.lastName}</h5>
            </div>
          </Col>

          {!localStorage.getItem("lead") && (
            <Col md={6} xl={4}>
              <div className="mb-2">
                <p className="text-muted fs-13 mb-1">Employee ID</p>
                <h5 className=" fs-15 text-dark mb-0">{userInfo?.employeeNo}</h5>
              </div>
            </Col>
          )}
          {!localStorage.getItem("admin") && (
            <Col md={6} xl={4}>
              <div className="mb-2">
                <p className="text-muted fs-13 mb-1">Employee ID</p>
                <h5 className="fs-15 text-dark mb-0">{userInfo?.employeeNo}</h5>
              </div>
            </Col>
          )}

          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">Email</p>
              <h5 className="fs-15 text-dark mb-0">{userInfo?.email}</h5>
            </div>
          </Col>
          <Col md={6} xl={4}>
            <div >
              <p className="text-muted fs-13 mb-1">Contact No </p>
              <h5 className="fs-15 text-dark mb-0">{userInfo?.contactNo}</h5>
            </div>
          </Col>
          <Col md={6} xl={4}>
            <div >
              <p className="text-muted fs-13 mb-1">Alternate Contact No</p>
              <h5 className="fs-15 text-dark">{userInfo?.altContactNo}</h5>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
