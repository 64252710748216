import React, { useState, useEffect } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Table, Button, Card, Container, Badge } from "react-bootstrap";
import Loader from "../common/Loader";
import JSZip from "jszip";
import Cookies from "js-cookie";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProjectData,
  newRequestt,
  realAllProject,
  seenProjectByAdmin,
} from "../../slices/Project";
import AddProject from "./AddProject";
import DeleteProjectModal from "./DeleteProject";
import EditProjectModal from "./EditProjectModal";
import { useNavigate } from "react-router-dom";
import { fetchLeadsData } from "../../slices/Lead";

const Project = () => {
  const employeeId = localStorage.getItem("employeeID");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Project, loading } = useSelector(
    (state) => state.project.allProjectData
  );
  const [lead] = useState(localStorage.getItem("lead"));
  const [admin] = useState(localStorage.getItem("admin"));
  const hr = localStorage.getItem("hr");
  const { leads } = useSelector((state) => state.leads.leadsData);
  const [add, setAdd] = useState(false);

  useEffect(() => {
    const fetchAllProject = async () => {
      try {
        const resultAction = await dispatch(fetchAllProjectData());
        const user = resultAction.payload;
        if (user === "Unauthorized"  || !localStorage.getItem("employeeID")) {
          localStorage.clear();
          Cookies.remove("token");
          navigate("/login");
        }
        
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllProject();

    let access = false;
    if (
      localStorage.getItem("hr") === "true" ||
      localStorage.getItem("admin") === "true"||
      localStorage.getItem("lead")==="true"
    ) {
      access = true;
    }
    dispatch(realAllProject({employeeId,access}));
    dispatch(seenProjectByAdmin())
  }, [dispatch]);

  DataTable.Buttons.jszip(JSZip);
  useEffect(() => {
    if (!$.fn.dataTable.isDataTable(".maindatatable")) {
      $(".maindatatable").DataTable({
        dom: "flrtip",
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            previous: "«",
            next: "»",
          },
        },
      });
    }
  }, [Project]);

  const [showModalThree, setShowModalThree] = useState(false);
  const handleCloseModalThree = () => setShowModalThree(false);
  const handleShowModalThree = () => setShowModalThree(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleDeleteProject = (project) => {
    setSelectedProject(project);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedProject(null);
  };

  useEffect(() => {
    if (add === true) {
      dispatch(fetchLeadsData());
    }
  }, [dispatch, add]);

  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditProject = (project) => {
    setSelectedProject(project);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedProject(null);
  };

  const options = [
    { value: "Active", label: "Active" },
    { value: "Done", label: "Done" },
    { value: "Blocked", label: "Blocked" },
  ];

  const statusColors = {
    Active: "soft-primary",
    Done: "soft-success",
    Blocked: "soft-danger",
  };

  const getStatusColor = (status) =>
    statusColors[status] || statusColors.default;

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <PageTitle breadcrumbLast="Project" />
        <Container>
          {loading ? (
            <Loader />
          ) : (
            <Card>
              <Card.Body>
                <div className="d-lg-flex d-md-flex justify-content-between align-items-center mb-3">
                  <Card.Title className="mb-lg-0" style={{fontSize:"17px"}}>Projects</Card.Title>
                  {lead === "true" || admin === "true" || hr === "true" ? (
                    <div className="d-md-flex">
                      <Button
                        variant="soft-primary mb-1 mb-md-0"
                        style={{fontSize:"14px",padding:"5px 10px"}}
                        className="me-2"
                        onClick={() => {
                          handleShowModalThree();
                          setAdd(true);
                        }}
                      >
                        <i className="bi bi-plus-circle me-2"></i>Add Project
                      </Button>
                    </div>
                  ) : null}
                </div>
                {Project?.length > 0 ? (
                  <Table
                    responsive
                    bordered
                    className="mb-0 table-nowrap maindatatable"
                  >
                    <thead className="table-light">
                      <tr>
                        <th>Project Name</th>
                        <th>Project Status</th>
                        <th>Project Lead</th>
                        {lead === "true" ||
                        admin === "true" ||
                        hr === "true" ? (
                          <th>Action</th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {Project.map((data, i) => (
                        <tr key={i}>
                          <td>{data.projectName}</td>
                          <td>
                            <Badge
                              pill
                              bg={getStatusColor(data.projectStatus)}
                              className="me-1"
                            >
                              {data.projectStatus}
                            </Badge>
                          </td>
                          <td>
                            {data.projectLead.map((lead, index) => (
                              <Badge
                                key={index}
                                pill
                                bg="primary"
                                className="me-1"
                              >
                                {lead.label}
                              </Badge>
                            ))}
                          </td>
                          {lead === "true" ||
                          admin === "true" ||
                          hr === "true" ? (
                            <td>
                              <div className="d-flex">
                                <Button
                                  variant="soft-primary table-btn me-2"
                                  onClick={() => handleEditProject(data)}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </Button>
                                <Button
                                  variant="soft-danger table-btn"
                                  onClick={() => handleDeleteProject(data)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </Button>
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : null}
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
      <Footer />
      <AddProject
        showModalThree={showModalThree}
        handleCloseModalThree={handleCloseModalThree}
        options={options}
        options1={leads}
      />
      <DeleteProjectModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        selectedProject={selectedProject}
      />
      <EditProjectModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        project={selectedProject}
        options={options}
        leadOptions={leads}
      />
    </div>
  );
};
export default Project;
