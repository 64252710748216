import React, { useState, useEffect } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import LogoSm from "../images/logo-sm.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
  
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { validateLogin } from "./common/Joi_Validation";
 

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailInput = email;

    const validateErrors = validateLogin({
      email: emailInput,
      password: password,
      rememberMe: rememberMe,
    });

    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      return;
    }

    // console.log("Validation Errors:", validateErrors);

    const pwd = {
      password : password}
      ;
    try {
      if (!login) {
        setLogin(true);
        const loggedUser = await axios.post(
          `${process.env.REACT_APP_API_BASE_PATH}/api/users/email/${emailInput}`,
          
             pwd,
         
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${emailInput}`,
            },
          }
        );


        localStorage.setItem("firstName", loggedUser?.data?.firstName);
        localStorage.setItem("lastName", loggedUser?.data?.lastName);
        localStorage.setItem("admin", loggedUser?.data?.admin);
        localStorage.setItem("employeeID", loggedUser?.data?.employeeId);
        localStorage.setItem("lead", loggedUser?.data?.lead);
        localStorage.setItem("hr", loggedUser?.data?.hr)
        localStorage.setItem("employeeStatus",loggedUser?.data?.employeeStatus );

        if (rememberMe) {
          localStorage.setItem("rememberMe", true);
          localStorage.setItem("token", loggedUser?.data?.token);
        
        } else {
          localStorage.setItem("rememberMe", false);
          Cookies.set("token",loggedUser?.data?.token)
         
        }
        toast.info("Login Successful!", {
          position: "top-right",
        });
    
    
        navigate("/");
        setLogin(false);
      }
    } catch (error) {
      if (!login) {
        setLogin(false);
        toast.error("Login Failed Please Try Again");
        console.error("Error logging in:", error);
      }
    }
  };

  return (
    <div className="auth-bg py-4 py-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col sm={10} md={7} lg={6} xxl={3}>
            <Card>
              <Card.Body>
                <div className="avtar avatar-xxxl bg-border-soft-primary rounded-circle d-flex align-items-center justify-content-center d-block mx-auto ">
                  <img
                    src={LogoSm}
                    alt="default"
                    width={70}
                    className="img-shadow rounded-circle"
                  />
                </div>
                <Card.Title className="text-center mt-3 mb-0">
                  Welcome!
                </Card.Title>
                <p className="text-center text-muted">
                  Please enter your details to login.
                </p>
                <Form>
                  <Form.Group
                    className="custom-fr-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email..."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      name="email"
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="custom-fr-group"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <div className="input-icon-content">
                      <i
                        className={`bi ${
                          showPassword ? "bi-eye" : "bi-eye-slash"
                        } input-icon`}
                        onMouseDown={() => setShowPassword(true)}
                        onMouseUp={() => setShowPassword(false)}
                        onMouseOut={() => setShowPassword(false)}
                        onTouchStart={() => setShowPassword(true)}
                        onTouchEnd={() => setShowPassword(false)}
                      ></i>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password..."
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    {errors.password && (
                      <p className="text-danger">{errors.password}</p>
                    )}
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Remember me"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      {errors.rememberMe && (
                        <p className="text-danger">{errors.rememberMe}</p>
                      )}
                    </Form.Group>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={login}
                    className="mt-3 btn btn-soft-primary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
