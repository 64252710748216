import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import user from "../../../images/user1.png";
import { formatDate } from "../../../utils/dateUtils";
import { addUser, fetchAllUsersData } from "../../../slices/Users";
import { validateEmployee } from "../../common/Joi_Validation";
import FileUploader from "../../common/bunny";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ModalHeader from "./addEmployeeFormComponent/ModalHeader";
import ModalImage from "./addEmployeeFormComponent/ModalImage";
import ModalFormBasicTextFields from "./addEmployeeFormComponent/ModalFormBasicTextFields";
import ModalFormBasicEmailFields from "./addEmployeeFormComponent/ModalFormBasicEmailFields";
import ModalFormDateField from "./addEmployeeFormComponent/ModalFormDateField";
import ModalFormMobileNoField from "./addEmployeeFormComponent/ModalFormMobileNoField";

export default function AddEmployeeModal({
  show,
  handleClose,
  id,
  add,
  setemployeeEditData,
  employeeEditData,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(user);
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [formData, setFormData] = useState({});
  const initialEmployeeData = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  // console.log(add, " ddddddddd ")

  useEffect(() => {
    if (show && employeeEditData) {
      setFormData(employeeEditData);
      initialEmployeeData.current = { ...employeeEditData };
    } else {
      setFormData({});
      initialEmployeeData.current = null;
    }
    setSelectedImageUrl(employeeEditData?.profilephoto || user); // Update profile photo preview
  }, [show, employeeEditData]);
  

  // console.log(employeeEditData, "11111111111 fdfffffffffffff ")

  // console.log(formData, " fdfffffffffffff ")

  const openFileInput = () => {
    document.getElementById("profilephoto").click();
  };

 

  useEffect(() => {
    if (employeeEditData && employeeEditData.profilephoto) {
      setSelectedImageUrl(employeeEditData.profilephoto || user);
    } else {
      setSelectedImageUrl(user);
    }
  }, [employeeEditData]);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    // console.log("file to send  ", file);
    if (file) {
      setSelectedFile(file);
      setSelectedImageUrl(URL.createObjectURL(file));
      setIsFormChanged(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setIsFormChanged(true);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null, // Clear error for the field on change
    }));
  };

  // console.log(" formfvf ", formData);
  const dateInputRef = useRef(null);

  const dateInputRef2 = useRef(null);

  const handleFocusDateInput = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
      dateInputRef.current.showPicker();
    }
  };

  const handleFocusDateInput2 = () => {
    if (dateInputRef2.current) {
      dateInputRef2.current.focus();
      dateInputRef2.current.showPicker();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData, " dddvfvfvfvfvfddddddd")

    try {
      // let profilephoto;
      // if (selectedFile) {
      //   profilephoto = await FileUploader(selectedFile);
      // }

      // console.log(requestData, " dddvfvfvfvfvfddddddd")

      // const validateErrors = validateEmployee(formData);

      // if (Object.keys(validateErrors).length > 0) {
      //   setErrors(validateErrors);
      //   return;
      // }

      let profilephoto = formData.profilephoto;
      if (selectedFile) {
        profilephoto = await FileUploader(selectedFile);
      }
      // console.log(add, " /dddvfvfvfvfvfddddddd")

      const data = {
        ...formData,
        profilephoto,
      };
      const cleanFormData = {
        aadharCardNo: formData.aadharCardNo,
        address: formData.address,
        altContactNo: formData.altContactNo,
        contactNo: formData.contactNo,
        dateOfBirth: formData.dateOfBirth,
        designation: formData.designation,
        email: formData.email,
        employeeSalary: formData.employeeSalary,
        employeeStatus: formData.employeeStatus,
        familyMember1FullName: formData.familyMember1FullName,
        familyMember1MobileNo: formData.familyMember1MobileNo,
        familyMember1Relation: formData.familyMember1Relation,
        familyMember2FullName: formData.familyMember2FullName,
        familyMember2MobileNo: formData.familyMember2MobileNo,
        familyMember2Relation: formData.familyMember2Relation,
        firstName: formData.firstName,
        gender: formData.gender,
        joiningDate: formData.joiningDate,
        lastName: formData.lastName,
        mobileLinkedToAadhar: formData.mobileLinkedToAadhar,
        nameOnAadhar: formData.nameOnAadhar,
        panCardMobileNo: formData.panCardMobileNo,
        panCardName: formData.panCardName,
        panCardNo: formData.panCardNo,
        password: formData.password,
        profilephoto: formData.profilephoto || profilephoto,
        role: formData.role,
      };

      const validateErrors = validateEmployee(cleanFormData);
      if (Object.keys(validateErrors).length > 0) {
        setErrors(validateErrors);
        return;
      }

      if (!addEmployeeModal) {
        setAddEmployeeModal(true);

        if (id) {
          // console.log(" to if the we inside th coditotog")

          await axios.patch(
            `${process.env.REACT_APP_API_BASE_PATH}/api/users/${id}`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  localStorage.getItem("token") || Cookies.get("token")
                }`,
              },
            }
          );
          setErrors({});
        } else {
          // console.log(" to if the we inside th coditotog")
          await axios.post(
            `${process.env.REACT_APP_API_BASE_PATH}/api/users`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  localStorage.getItem("token") || Cookies.get("token")
                }`,
              },
            }
          );
          setErrors({});
        }

        dispatch(addUser(employeeEditData));
        dispatch(fetchAllUsersData());
        setAddEmployeeModal(false);
      }

      handleClose();
    } catch (error) {
      if (!addEmployeeModal) {
        toast.error("Error submitting employee data:", {
          position: "top-right",
        });
        console.error("Error submitting employee data:", error);
        setAddEmployeeModal(false);
        if (error.response.status === 401) {
          console.log(error.response.status, "error.response.status");
          localStorage.clear();
          Cookies.remove("token");

          navigate("/login");
        }
      }
    }
  };

  const handleCancel = () => {
    handleClose();
    setFormData(initialEmployeeData.current || {});
    // setFormData(initialEmployeeData.current);
    // setFormData({});
    setIsFormChanged(false);
    setErrors({});
  };
  const isDataChanged = () => {
    return (
      JSON.stringify(formData) !== JSON.stringify(initialEmployeeData.current)
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        // setFormData(initialEmployeeData.current);
        // setFormData({});
        setFormData(initialEmployeeData.current || {});
        setIsFormChanged(false);
        setErrors({});
      }}
      dialogClassName="modal-xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader />
      <Modal.Body>
        <Container>
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col lg={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="position-relative mb-5">
                    <ModalImage
                      selectedImageUrl={selectedImageUrl}
                      errors={errors.profilephoto}
                      openFileInput={openFileInput}
                    />

                    <Form.Group controlId="profilephoto" className="mb-3">
                      <Form.Control
                        type="file"
                        name="profilephoto"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handlePhotoUpload}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <ModalFormBasicTextFields
                controlIdAndName="firstName"
                formLabelAndPlaceholder="First Name"
                value={formData?.firstName}
                changeFunction={handleChange}
                errors={errors.firstName}
              />
              <ModalFormBasicTextFields
                controlIdAndName="lastName"
                formLabelAndPlaceholder="Last Name"
                value={formData?.lastName}
                changeFunction={handleChange}
                errors={errors.lastName}
              />
              <ModalFormBasicEmailFields
                controlId="email"
                label="Email"
                value={formData?.email}
                onChange={handleChange}
                errors={errors.email}
              />
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <div className="input-icon-content">
                  <i
                        className={`bi ${
                          showPassword ? "bi-eye" : "bi-eye-slash"
                        } input-icon`}
                       onClick={handleShowPassword}
                      ></i>
                  <Form.Control
                  type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={formData?.password}
                    onChange={handleChange}
                  />
                    </div>
                  {errors.password && (
                    <p className="text-danger">{errors.password}</p>
                  )}
                </Form.Group>
                
              </Col>
              <ModalFormDateField
                controlId="joiningDate"
                label="Joining Date"
                value={formData?.joiningDate}
                onChange={handleChange}
                formatDate={formatDate} // Ensure formatDate function is defined
                handleFocusDateInput={handleFocusDateInput} // Ensure handleFocusDateInput function is defined
                errors={errors.joiningDate}
                dateInputRef={dateInputRef} // Ensure dateInputRef is defined
              />
            </Row>

            <Row>
              <ModalFormMobileNoField
                controlId="contactNo"
                label="Contact No"
                placeholder="Enter Contact No of 10 digits"
                name="contactNo"
                value={formData?.contactNo}
                onChange={handleChange}
                errors={errors.contactNo}
              />

              <ModalFormMobileNoField
                controlId="altContactNo"
                label="Alternate Contact No"
                placeholder="Enter Alternate Contact No of 10 digits"
                name="altContactNo"
                value={formData?.altContactNo}
                onChange={handleChange}
                errors={errors.altContactNo}
              />

              <ModalFormBasicTextFields
                controlIdAndName="employeeSalary"
                formLabelAndPlaceholder="Employee Salary"
                value={formData?.employeeSalary}
                changeFunction={handleChange}
                errors={errors.employeeSalary}
              />
            </Row>

            <Row>
              <Col lg={4}>
                <Form.Group controlId="role" className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select" // Render as a select input
                    name="role"
                    value={formData?.role}
                    onChange={handleChange}
                  >
                    <option value="">Select Role...</option>
                    <option value="Admin">Admin</option>
                    <option value="Lead">Lead</option>
                    <option value="HR">HR</option>
                    <option value="Employee">Employee</option>
                  </Form.Control>
                  {errors.role && (
                    <p className="text-danger">
                      Role is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="designation" className="mb-3">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    as="select" // Render as a select input
                    name="designation"
                    value={formData?.designation}
                    onChange={handleChange}
                  >
                    <option value="">Select Designation...</option>
                    <option value="Project Manager">Project Manager</option>
                    <option value="React Developer">React Developer</option>
                    <option value="Lead">Native Developer</option>
                    <option value="Full Stack Developer">
                      Full Stack Developer
                    </option>
                    <option value="Frontend Developer">
                      Frontend Developer
                    </option>
                    <option value="Backend Developer">Backend Developer</option>
                  </Form.Control>
                  {errors.designation && (
                    <p className="text-danger">
                      Designation is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="employeeStatus" className="mb-3">
                  <Form.Label>Employee Status</Form.Label>

                  <Form.Control
                    as="select" // Render as a select input
                    name="employeeStatus"
                    value={formData?.employeeStatus}
                    onChange={handleChange}
                  >
                    <option value="">Select Status...</option>
                    <option value="working">Working</option>
                    <option value="remoteWorker">Remote Worker</option>
                    <option value="left">Left</option>
                    <option value="suspended">Suspended</option>
                  </Form.Control>
                  {errors.employeeStatus && (
                    <p className="text-danger">
                      Employee Status is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <h5> Personal Details</h5>
            <Row>
              <Col lg={12}>
                <Form.Group controlId="address" className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="textarea"
                    rows="3"
                    placeholder="Enter Address..."
                    name="address"
                    value={formData?.address}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <p className="text-danger">
                      Address is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <ModalFormDateField
                controlId="dateOfBirth"
                label="Date of Birth"
                value={formData?.dateOfBirth}
                onChange={handleChange}
                formatDate={formatDate} // Ensure formatDate function is defined
                handleFocusDateInput={handleFocusDateInput2} // Ensure handleFocusDateInput2 function is defined
                errors={errors.dateOfBirth}
                dateInputRef={dateInputRef2} // Ensure dateInputRef2 is defined
              />
              <Col lg={6}>
                <Form.Group controlId="gender" className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={formData?.gender}
                    onChange={handleChange}
                  >
                    <option value="">Select Gender...</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                  {errors.gender && (
                    <p className="text-danger">
                      Gender is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <ModalFormBasicTextFields
                controlIdAndName="nameOnAadhar"
                formLabelAndPlaceholder="Name as per Aadhar Card"
                value={formData?.nameOnAadhar}
                changeFunction={handleChange}
                errors={errors.nameOnAadhar}
              />

              <ModalFormBasicTextFields
                controlIdAndName="aadharCardNo"
                formLabelAndPlaceholder="Aadhar Card Number"
                value={formData?.aadharCardNo}
                changeFunction={handleChange}
                errors={errors.aadharCardNo}
              />

              <ModalFormMobileNoField
                controlId="mobileLinkedToAadhar"
                label="Mobile No. Linked to Aadhar Card"
                placeholder="Enter Contact No Linked to Aadhar Card of 10 digits"
                name="mobileLinkedToAadhar"
                value={formData?.mobileLinkedToAadhar}
                onChange={handleChange}
                errors={errors.mobileLinkedToAadhar}
              />
            </Row>

            <Row>
              <ModalFormBasicTextFields
                controlIdAndName="panCardName"
                formLabelAndPlaceholder="Name As Per Pan Card"
                value={formData?.panCardName}
                changeFunction={handleChange}
                errors={errors.panCardName}
              />

              <ModalFormBasicTextFields
                controlIdAndName="panCardNo"
                formLabelAndPlaceholder="PAN Card Number"
                value={formData?.panCardNo}
                changeFunction={handleChange}
                errors={errors.panCardNo}
              />

              <ModalFormMobileNoField
                controlId="panCardMobileNo"
                label="Mobile No. Linked to Pan Card"
                placeholder="Enter Contact No Linked to Pan Card of 10 digits"
                name="panCardMobileNo"
                value={formData?.panCardMobileNo}
                onChange={handleChange}
                errors={errors.panCardMobileNo}
              />
            </Row>

            <Row>
              <ModalFormBasicTextFields
                controlIdAndName="familyMember1FullName"
                formLabelAndPlaceholder="Family Member 1 Full Name"
                value={formData?.familyMember1FullName}
                changeFunction={handleChange}
                errors={errors.familyMember1FullName}
              />
              <ModalFormMobileNoField
                controlId="familyMember1MobileNo"
                label="Family Member 1 Mobile No"
                placeholder="Enter Family Member 1 Contact No of 10 digits"
                name="familyMember1MobileNo"
                value={formData?.familyMember1MobileNo}
                onChange={handleChange}
                errors={errors.familyMember1MobileNo}
              />
              <ModalFormBasicTextFields
                controlIdAndName="familyMember1Relation"
                formLabelAndPlaceholder="Family Member 1 Relation"
                value={formData?.familyMember1Relation}
                changeFunction={handleChange}
                errors={errors.familyMember1Relation}
              />
            </Row>

            <Row>
              <ModalFormBasicTextFields
                controlIdAndName="familyMember2FullName"
                formLabelAndPlaceholder="Family Member 2 Full Name"
                value={formData?.familyMember2FullName}
                changeFunction={handleChange}
                errors={errors.familyMember2FullName}
              />

              <ModalFormMobileNoField
                controlId="familyMember2MobileNo"
                label="Family Member 2 Mobile No"
                placeholder="Enter Family Member 2 Contact No of 10 digits"
                name="familyMember2MobileNo"
                value={formData?.familyMember2MobileNo}
                onChange={handleChange}
                errors={errors.familyMember2MobileNo}
              />
              <ModalFormBasicTextFields
                controlIdAndName="familyMember2Relation"
                formLabelAndPlaceholder="Family Member 2 Relation"
                value={formData?.familyMember2Relation}
                changeFunction={handleChange}
                errors={errors.familyMember2Relation}
              />
            </Row>
            <Button
              variant="primary"
              type="submit"
              disabled={addEmployeeModal || !isFormChanged || !isDataChanged()}
              className="me-4"
            >
              {addEmployeeModal ? "Submitting..." : "Submit"}
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
