import React from "react";

export default function ModalImage({ selectedImageUrl, openFileInput, errors }) {
  const handleClick = () => {
    openFileInput(); // Ensure openFileInput is a function
  };

  return (
 
      <div className="d-flex align-items-center justify-content-center">
        <img
          src={selectedImageUrl}
          className={`rounded-circle img-thumbnail avatar-img-xxl ${
            errors ? "bg-danger" : "bg-white"
          }`}
          alt="Profile"
        />
        <div className="add-btn rounded-circle avatar avatar-xs bg-primary text-white" onClick={handleClick}>
          <i className="bi bi-plus"></i>
        </div>
      </div>
 
  );
}
