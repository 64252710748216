import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socketIO } from "../App";
import { fetchLeaves, markLeavesAsViewed, newRequest, realAllLeaves, seenRequestids } from "../slices/leaveSlice";

export const SocketClient = () => {
  const employeeId=localStorage.getItem("employeeID");
  const isAdminOrLead =
    localStorage.getItem("admin") === "true" ||
    localStorage.getItem("hr") === "true";
  const dispatch = useDispatch();

useEffect(() => {
    socketIO.on("pendingLeaveRequest",  (data) => {
        if(isAdminOrLead){
          dispatch(seenRequestids())
          // dispatch(newRequest(true));
          // dispatch(fetchLeaves(employeeId))
      }  
    });
    return () => {
        socketIO.off("pendingLeaveRequest");
    };

  }, [dispatch]);

  return null;
};
