import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

export default function WorkInfoComponent({ userInfo }) {

  return (
    <Card>
      <Card.Body  >
        <Card.Title>Work Info</Card.Title>
        <Row>
          <Col lg={6} md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">Salary </p>
              <h5 className="text-dark fs-15 mb-0">&#8377;{userInfo?.employeeSalary}</h5>
            </div>
          </Col>
          <Col lg={6} md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">Designation</p>
              <h5 className="text-dark fs-15 mb-0">{userInfo?.designation}</h5>
            </div>
          </Col>
          <Col lg={6} md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">Joining Date</p>
              <h5 className="text-dark fs-15 mb-0">
                {userInfo?.joiningDate ? new Date(userInfo?.joiningDate).toLocaleDateString("en-GB") : null}
              </h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}  md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">Name on Adhaar Card</p>
              <h5 className="text-dark fs-15 mb-0">{userInfo?.nameOnAadhar}</h5>
            </div>
          </Col>
          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1"> Aadhar Card No.</p>
              <h5 className="text-dark fs-15 mb-0">{userInfo?.aadharCardNo}</h5>
            </div>
          </Col>
          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">
                {" "}
                Mobile No. Linked to Aadhar Card
              </p>
              <h5 className="text-dark fs-15 mb-0">
                {userInfo?.mobileLinkedToAadhar}
              </h5>
            </div>
          </Col>
          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1"> Name on Pan Card</p>
              <h5 className="text-dark fs-15 mb-0">{userInfo?.panCardName}</h5>
            </div>
          </Col>

          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1"> PAN Card No.</p>
              <h5 className="text-dark fs-15 mb-0">{userInfo?.panCardNo}</h5>
            </div>
          </Col>

          <Col md={6} xl={4}>
            <div className="mb-2">
              <p className="text-muted fs-13 mb-1">
                {" "}
                Mobile No. Linked to Pan Card
              </p>
              <h5 className="text-dark fs-15 mb-0">{userInfo?.panCardMobileNo}</h5>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
