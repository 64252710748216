import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { fetchAllTimesheetData } from "../../slices/Timesheet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { validateTimesheetEdit } from "../common/Joi_Validation";
import Cookies from "js-cookie";
import { socketIO } from "../../App";

export default function EditModal({
  show,
  handleClose,
  selectedEmployee,
  textareaRef,
  fromReadMore,
  Project,
  leads,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
 
  const dateInputRef = useRef(null);
  const [editModal, setEditModal] = useState(false);

  const [initialData, setInitialData] = useState(null);

  const [formData, setFormData] = useState({
    _id: selectedEmployee?._id || "",
    project: selectedEmployee?.project || [],
    date: selectedEmployee?.date || "",
    details: selectedEmployee?.details || "",
    projectLead: selectedEmployee?.projectLead || [],
    employee: selectedEmployee?.employee || "",
  });

  useEffect(() => {
    if (selectedEmployee) {
      const initialTimesheetData = {
        _id: selectedEmployee?._id || "",
        project: selectedEmployee?.project || [],
        date: selectedEmployee?.date || "",
        details: selectedEmployee?.details || "",
        projectLead: selectedEmployee?.projectLead || [],
        employee: selectedEmployee?.employee || "",
      };
      setFormData(initialTimesheetData);
      setInitialData(initialTimesheetData);
    }
  }, [selectedEmployee]);

  const handleFocusDateInput = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
      dateInputRef.current.showPicker();
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  const projectOptions = Project?.filter(
    (project) => project?.projectStatus !== "Blocked"  && project?.projectStatus !== "Done"
  ).map((project) => ({
    value: project.projectName,
    label: project.projectName,
  }));

  const handleFormChange = (e) => {
    var { name, value } = e.target;
    value = value.replace(/'/g, '"');
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  
  const editTimesheet = async () => {
    const timesheet = {
      ...formData,
      edittedBy: localStorage.getItem("firstName"),
    };

    const validationErrors = validateTimesheetEdit(timesheet);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      if (!editModal) {
        setEditModal(true);

        await axios.patch(
          `${process.env.REACT_APP_API_BASE_PATH}/api/timesheets/${timesheet?._id}`,
          timesheet,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                localStorage.getItem("token") || Cookies.get("token")
              }`,
            },
          }
        );

        const employeeId = localStorage.getItem("employeeID");
        socketIO.emit("timesheetnotification");
        dispatch(fetchAllTimesheetData({ employeeId }));
        toast.info("Edited Timesheet Successfully!", {
          position: "top-right",
        });

        setErrors({});
        setEditModal(false);
        handleClose();
      }
    } catch (error) {
      if (!editModal) {
        toast.error("Editing Timesheet Caused Error!", {
          position: "top-right",
        });
        console.error("Error creating timesheet:", error);
        setEditModal(false);
        if (error.response.status === 401) {
          console.log(error.response.status, "error.response.status");
          localStorage.clear();
          Cookies.remove("token");
          navigate("/login");
        }
      }
    }
  };


  const isDataChanged = () => {
    if (!initialData) return false;

    const currentData = {
      _id: formData._id,
      project: formData.project,
      date: formData.date,
      details: formData.details,
      projectLead: formData.projectLead,
      employee: formData.employee,
    };

    return (
      initialData._id !== currentData._id ||
      JSON.stringify(initialData.project) !== JSON.stringify(currentData.project) ||
      initialData.date !== currentData.date ||
      initialData.details !== currentData.details ||
      JSON.stringify(initialData.projectLead) !== JSON.stringify(currentData.projectLead) ||
      initialData.employee !== currentData.employee
    );
  };


  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        handleClose();
        setFormData(selectedEmployee)
        setErrors({});
      }}
      dialogClassName="modal-lg"
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>Edit Timesheet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div onClick={handleFocusDateInput} style={{ cursor: "pointer" }}>
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                disabled={fromReadMore}
                ref={dateInputRef}
                value={formatDate(formData?.date)}
                onChange={handleFormChange}
              />
              {errors.date && <p className="text-danger">{errors.date}</p>}
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Label>Project Manager</Form.Label>
            <Select
              isMulti
              options={leads}
              isDisabled={fromReadMore}

              value={formData?.projectLead}
              onChange={(selectedOptions) => {
                setFormData((prev) => ({
                  ...prev,
                  projectLead: selectedOptions,
                }));
              }}
            />
            {errors.projectLead && (
              <p className="text-danger">Project Manager is not allowed to be empty</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Project</Form.Label>
            <Select
              isMulti
              options={projectOptions}
              
              isDisabled={fromReadMore}

              value={formData?.project}
              onChange={(selectedOptions) => {
                setFormData((prev) => ({
                  ...prev,
                  project: selectedOptions,
                }));
              }}
            /> 
            {errors.project && <p className="text-danger">Project  is not allowed to be empty</p>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={25}
              disabled={fromReadMore}

              name="details"
              value={formData?.details}
              ref={textareaRef}
              style={{
                overflow: "visible",
                minHeight: "40px",
                maxHeight: "auto",
                resize: "none",
              }}
              onChange={handleFormChange}
            />
            {errors.details && <p className="text-danger">Details is not allowed to be empty</p>}
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer className="border-0 pt-0">
        { !fromReadMore && (
          <div>
            <Button variant="danger light" className="me-3"  onClick={() => {
                handleClose();
                setErrors({});
              }}>
              Close
            </Button>
            <Button
              variant="primary"
              // disabled={editModal}
              disabled = {editModal || !isDataChanged()}
              onClick={editTimesheet}
            >
              {editModal ? "Editing..." : "Save Changes"}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
