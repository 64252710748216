import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  user: { userInfo: {}, loading: false, success: false, error: null },
};

const employeeId = localStorage.getItem("employeeID")


export const loggedUser = createAsyncThunk(
  "loggedInUser/loggedUser",

  async () => {
    const loggedUser = localStorage.getItem("firstName");

    return loggedUser;
  }
);

const loggedInUser = createSlice({
  name: "loggedInUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loggedUser.pending, (state) => {
        state.user.loading = true;
      })
      .addCase(loggedUser.fulfilled, (state, action) => {
        state.user.loading = false;
        state.user.info = true;
        state.user.userInfo = action.payload;
      })
      .addCase(loggedUser.rejected, (state, action) => {
        state.user.loading = false;
        state.user.error = action.error.message;
      });
  },
});

export default loggedInUser.reducer;
